import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`이 폴더의 컴포넌트들은 원래 Keyshape 라는 애니메이션 프로그램으로 만들어진 SVG 파일이었으나,
Gatsby 에서 SVG 를 바로 사용하기 어려워서 React 컴포넌트 형태로 만든 후 mdx 파일에서 가져다 사용 중`}</p>
    <p>{`LazySVG 및 다른 컴포넌트들은 FF, Chrome, Safari 에서 정상작동 확인됨`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      